<template>
    <div class="position-sticky search-bar d-flex">
        <div class="position-relative flex-grow-1 mr-2">
            <b-form-input class="border-0 rounded-pill shadow"
                          placeholder="🔍 Search"
                          type="search"
                          v-model="keyword"
                          v-on:input="$emit('input', keyword)">
            </b-form-input>
        </div>
        <div v-if="hasFilter">
            <slot name="filter"></slot>
        </div>
    </div>
</template>

<script>
import { BFormInput, } from "bootstrap-vue";
export default {
    name: "SearchBar",
    components: {
        BFormInput,
    },
    props: {
        "value": {
            type: String,
        },
        "hasFilter": {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            keyword: "",
        };
    },
    computed: {

    },
    methods: {

    },
    watch: {
        statusSelected (newVal, oldVal) {
            if (newVal.length === 0) {
                this.statusSelected = oldVal;
            }
            this.$emit("update:statusFilter", this.statusSelected);
        },
    }
}
</script>

<style lang="scss" scoped>
.search-bar {
    min-width: 180px;
}
</style>
